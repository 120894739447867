<template>
  <v-container fluid class="diminBg">

    <router-link to="/talleres">
      <v-btn color="primary">
        <v-icon class="pr-2">mdi-arrow-left</v-icon>
        Volver
      </v-btn>
    </router-link>

    <div v-if="!isLoading">

      <v-container>

        <v-row>
          <v-col>
            <v-card class="mx-auto mt-5 pa-0" elevation="3">

              <v-row class="my-0">
                <v-col cols="12" align="center" class="pb-0 pt-0">

                  <v-card class="titleUnderline" min-height="45px" elevation="3">
                    <p class="text-h4 white--text pa-4 my-auto">{{course.name}}</p>

                    <v-btn to="/inscripcion" v-if="course.inRegistrationProcess" color="teal" dark elevation="5" small class="ml-3 mb-3">
                      <v-icon class="mr-2">mdi-draw-pen</v-icon>
                      Inscribete aquí!
                    </v-btn>

                  </v-card>
                </v-col>
              </v-row>
              
              <v-container class="pt-4">

                <v-row>

                  <v-col md="7" sm="12">

                    <v-carousel :cycle="!course.youtubeLink">

                      <v-carousel-item v-if="course.youtubeLink">

                        <iframe
                          title="YouTube video player"
                          width="100%"
                          height="90%"
                          :src="course.youtubeLink"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        >
                        </iframe>

                      </v-carousel-item>

                      <v-carousel-item
                        v-for="(item,i) in course.imgURL"
                        :key="`course-${i}`"
                        :src="`${apiURL}/uploads/${item}`"
                      />
                    </v-carousel>

                    <v-card-subtitle class="mb-0 pb-0">
                      <v-chip
                        bottom
                        align="center"
                        color="blue"
                        label
                        small
                        outlined
                        class="mb-2"
                      >
                          <v-icon left> mdi-draw-pen </v-icon>
                          <strong>Nivel</strong>: {{course.level}}
                      </v-chip>
                      <v-chip
                        v-if="course.inRegistrationProcess"
                        bottom
                        align="center"
                        color="green"
                        label
                        small
                        outlined
                        class="mb-2 ml-2"
                      >
                          <v-icon left> mdi-draw-pen </v-icon>
                          Inscripciones abiertas
                      </v-chip>
                    </v-card-subtitle>

                    <v-divider></v-divider>

                    <v-card-text>

                    <v-card-subtitle class="mb-0 pt-1">{{course.shortDescription}}</v-card-subtitle>

                      <v-card-subtitle class="text--primary">
                        {{course.description}}
                      </v-card-subtitle>

                      <v-alert
                        color="blue"
                        outlined
                        icon="mdi-calendar"
                        dark
                        dense
                        class="py-1"
                      >
                        
                        <span> Horarios </span>

                        <v-divider></v-divider>

                        <v-chip-group v-if="course.schedules && course.schedules.length > 0" column >
                          <v-chip v-for="(sch, idx) in course.schedules" :key="idx" color="primary" label>
                            {{sch.day}} {{sch.start}} hrs.
                          </v-chip>
                        </v-chip-group>

                        <v-alert v-else color="orange" type="warning" dense text class="my-3">
                          No hay horarios disponibles para este taller
                        </v-alert>
                        
                      </v-alert>

                    </v-card-text>

                  </v-col>

                  <!-- Teacher bio -->
                  <v-col md="5" sm="12">

                    <v-card dark text class="mx-2 pa-0" elevation="5" >

                      <v-carousel cycle height="auto">
                        <v-carousel-item
                          v-for="(item,i) in course.professor.imgURL"
                          :key="i"
                          :src="`${apiURL}/uploads/${item}`"
                        />
                      </v-carousel>

                      <v-list two-line class="mb-3" color="blue darken-1">
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img :src="(course.professor.imgURL[0]) ? `${apiURL}/uploads/${course.professor.imgURL[0]}` : null"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{course.professor.name}}</v-list-item-title>
                            <v-list-item-subtitle>Profesor/a</v-list-item-subtitle>
                          </v-list-item-content>

                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item>
                          <v-list-item-content>
                            <p class="text-body-2">{{course.professor.description}}</p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                    </v-card>

                  </v-col>

                </v-row>

              </v-container>

            </v-card>
          </v-col>
        </v-row>

      </v-container>

    </div>

    <LoadingIndicator v-else />
    
  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
  name: "ViewCourse",
  components: {
    LoadingIndicator,
  },
  computed: {
    apiURL() {
      return process.env.VUE_APP_APIURL
    },
  },
  data:() => {
    return {
      //state
      isLoading: false,
      //data
      course: {
        _id: null,
        name: null,
        professor: {
          imgURL: [],
          _id: null,
          name: null,
          email: null,
          phone: null,
          description: null,
        },
        level: null,
        description: null,
        imgURL: null,
        schedules: [],
        shortDescription: null,
        inRegistrationProcess: false
      }
    }
  },
  methods: {
    async loadCourse() {
      try {

        this.isLoading = true

        if(this.$route.params.id) {
          const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/course/id/${this.$route.params.id}`)
          Object.assign(this.course, resp.data.data)
        }

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.loadCourse()
  }
}
</script>

<style lang="scss" scoped>

.hoverExpand {
  transition: all .15s;
  background-color: white;

  & .v-image {
    transition: all .15s;
  }

  &:hover {
    transform: scale(1.015);
    cursor: pointer;

    & .v-image {
      filter: opacity(90%);
    }
  }
}
</style>

<style lang="scss" scoped>

.diminBg {
  background-color: #ededed;
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, #2980b9 25%, #2c3e50 10%);
  border-radius: 5px 5px 0px 0px;
}
</style>